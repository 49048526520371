import {React, useRef} from 'react';
import WindowPage from './pages/WindowPage';
import { MobilePage } from './pages/MobilePage';
import DashBoard from './pages/DashBoard';
import Product1 from './pages/Product1';



import { createBrowserRouter, RouterProvider } from 'react-router-dom';

function App() {
  const width = window.innerWidth;
  const breakpoint = 1024;
  const Productref = useRef();
  const Memberref = useRef();
  const Contactref = useRef();


  const Window_router = createBrowserRouter([
  {
    path: '/',
    element:      
      <WindowPage
        Productref={Productref}
        Memberref={Memberref}
        Contactref={Contactref}
      />
  },
  {
    path: 'DashBoard',
    element: <DashBoard
      key="dashboard"
    />
  },
  {
    path: 'Product1',
    element: <Product1
      key="product1"
    />
  },
  ]);

  const App_router = createBrowserRouter([{
    path: '/',
    element:      
        <MobilePage
          Productref={Productref}
          Memberref={Memberref}
          Contactref={Contactref}
        />
  }]);


  return(
      <>
        <div>
          <RouterProvider router={Window_router} />
        </div>
      </>
    )
}

export default App;
