import React, {useState} from 'react';
import './CSS/Web.css'
import './CSS/LoginBox.css'
import './CSS/CMS.css'
import { useNavigate } from 'react-router-dom';
import {StyleSheet, View} from 'react-native';

import LoginBox from './Components/LoginBox';
import CMS from './Components/CMS';


const Style = StyleSheet.create({
    MainBody:{
        alignSelf: 'center',     
        //display: 'flex',
        alignContent: 'center',
        justifyContent: 'center', 
        height: '100%',
        width: '100%',
        padding: 'auto',
        backgroundColor: '#c7c0b3'

    },
})

const Login_Style = StyleSheet.create({
    LoginPage:{
        display: 'flex',
        flexDirection: 'row',
       
        //backgroundColor:'coral'
        //background:'repeating-linear-gradient(45de, #000 0, #000 5%, #f90 5%, #f90 10%)'
        
    },
    LoginBox:{
        minWidth:'350px',

    },
    LoginImg:{
        objectFit: 'cover',
        
        
    }

})


const Logout = (Navigator,Callback) => {
    Callback("False");
}

    
export default function DashBoard(props) {  

    const [Login,setLogin] = useState("False");
    const [AWS_client,setAWS_client] = useState()
    //const [data, setData] = useState([])
    const Navigator = useNavigate();

    let Logout_Session = [];
    let Body_Session = [];

    if (Login === 'True') {
        Logout_Session.concat(
            <p key="LogoutButton" onClick={() => Logout(Navigator,setLogin)}> Logout </p> 
        );
        Body_Session = Body_Session.concat(
            <CMS 
                //TableData={data}
                User={AWS_client}
                Navigator={Navigator}
                Logout_Callback={setLogin}
            />
        )
    } else {
        Body_Session = Body_Session.concat(
            <View key="loginContainer" style={Login_Style.LoginPage} >
                        {/*<img style={Login_Style.LoginImg} alt="left" src="LoginLeft.jpg"/>*/}
                        <LoginBox 
                            key="login"
                            Login_Callback= {setLogin}
                            User_Callback = {setAWS_client}
                        />
            </View>
        )
    }

    // console.log(props)

    return (
    <div className="Main-Window">
        <View style={Style.MainBody}>
            {Body_Session}
        </View>
    </div>
    );
    
}
