import React from 'react';
import "./CSS/Product.css";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { TypeAnimation } from "react-type-animation";



const navigateTo = (Navigator,Destination) => { 
    Navigator(Destination);
}
const getDetail=(evt, Name)=> {
   
    var i, tabcontent, tablinks;
  
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
  
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
  

    document.getElementById(Name).style.display = "block";
    evt.currentTarget.className += " active";
  }

export default function WindowPage(props) {  
    const Navigator = useNavigate();
    
    return (
        // <React.Fragment>
        <div className="p-home-container">
            <div className="p-home-container01">
                <div className="p-home-first" >
                    
                    <header data-thq="thq-navbar" className="p-home-navbar-interactive">
                        <img
                        alt="logo"
                        src="logo_horizontal.png"
                        className="home-image1"
                        onClick={() => navigateTo(Navigator,"/")} 
                        />
                    </header> 
                    <div className="p-home-hero">
                        <div className="p-home-container2">
                            <h1 className="p-home-text11">
                                <TypeAnimation
                                sequence={[
                                "Moss Power Battery",
                                1000,
                                "",
                                1000,
                                ]}
                                speed={50}
                                cursor={true}
                                repeat={Infinity}
                                />
                            </h1>
                            <p className="p-home-text12">
                                <span>
                                    <span>
                                    Welcome to Moss Power Battery, where we harness the untapped potential of microbial electricity to create innovative and sustainable green renewable energy solutions.
                                    </span>
                                    
                                </span>
                        
                            </p>

                            <p className="p-home-text12">
                                <h2>Feature</h2><br></br>
                                <span>
                                    <p>
                                    With our revolutionary solution, you can seamlessly integrate our Moss Power Battery onto rooftops and gardens, much like a natural plant. By utilizing available space to generate green energy, 
                                    you not only contribute to a sustainable future but also enjoy a cost-effective solution. 
                                    </p><br></br>
                                    {/* <p>
                                    Product introduction is the marketing tactic used to increase sales by advertising a product before it's released. 
                                    A company's sales or marketing division is usually in charge of this process that begins when a product has finished testing or been authorized for sales. The marketing team uses techniques to create a consumer response, including excitement about new ideas or solutions. For example, a company schedules a new phone to release in three years. In order to increase sales, the company may create advertisements, host demonstrations or create a temporary production team in anticipation of the product's arrival.
                                    </p><br></br>
                                    <p>
                                    Product introduction is the marketing tactic used to increase sales by advertising a product before it's released. 
                                    A company's sales or marketing division is usually in charge of this process that begins when a product has finished testing or been authorized for sales. The marketing team uses techniques to create a consumer response, including excitement about new ideas or solutions. For example, a company schedules a new phone to release in three years. In order to increase sales, the company may create advertisements, host demonstrations or create a temporary production team in anticipation of the product's arrival.
                                    </p> */}
                                    
                                </span>
                        
                            </p>
                           
                        
                            <p className="p-home-text12">
                                <h2>Details</h2><br></br>
                                {/* <span className="left">
                                    <span>
                                        Size: 100cm x 100cm x 5cm
                                    </span><br></br>
                                    <span>
                                        Power Generate: 100uW
                                    </span><br></br>
                                    <span>
                                        Product Duration: 2 Years (minimum)
                                    </span><br></br>
                                </span>
                                <span className="right">
                                    <span>
                                    CO<sub>2</sub> Absorption: 3300kg (annually)
                                    </span><br></br>
                                    <span>
                                        Temperature Reduction: 0.9&deg;C
                                    </span><br></br>
                                    
                                </span> */}
                                <div class="table-container">
                                    <table>
                                        <tr>
                                        <td>Size: 100cm x 100cm x 5cm</td>
                                        <td>CO<sub>2</sub> Absorption: 3300kg (annually)</td>
                                        </tr>
                                        <tr>
                                        <td>Power Generate: 100uW</td>
                                        <td>Temperature Reduction: 0.9&deg;C</td>
                                        </tr>
                                        <tr>
                                        <td>Product Duration: 2 Years (minimum)</td>
                                       
                                        </tr>
                                    </table>
                                </div>
                        
                            </p>
                        </div>
                        <img
                            alt="image1"
                            // src="https://images.unsplash.com/photo-1525498128493-380d1990a112?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDI0fHxtaW5pbWFsaXNtJTIwZ3JlZW58ZW58MHx8fHwxNjI1ODQxMDcw&amp;ixlib=rb-1.2.1&amp;w=800"
                            src="MP.jpg"
                            className="p-home-image1"
                        />
                        <br></br>
                        
                    </div>

                    
                </div>
                <div className="footer-basic"> 
                <footer>         
                    <p className="copyright">© Copyright 2023 Factgie. All rights reserved</p>         
                </footer>
            </div>
                
            </div>
            
        </div>
        
        //</React.Fragment>
    );  
}
