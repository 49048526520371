import React, {Component} from 'react';
import './CSS/Mobile.css'

export class MobilePage extends Component {
    render() {                
        return (
        <div className="Mobile">
            <header className="Mobile-header">
                <img src={"logo_horizontal.png"} className="Mobile-Icon" alt="Icon"/>
            </header>
            <div className="Mobile-Block" ref={this.props.Homeref}>
            <img src={"Intro-photo.jpg"} alt="Intro" className=""></img>
            <div className="Mobile-IntroText">
                <p className="Mobile-IntroTitle">Welcome to Factgie</p>
                <p>As an agricultural technology company, we are committed to combatting world hunger and promoting sustainable environmental practices. <br/>Our vision is to revolutionize agriculture, empowering farmers with cutting-edge technologies for increased productivity and resource management.</p>
            </div>
            </div>
        
            <h1 className="Mobile-subtitle" >Our Products</h1>
            <div className="Mobile-Block" ref={this.props.Productref}>
            <div className="Mobile-Product">
                <img src={"VAS.png"} alt="VAS" className=""></img>
                <p className='title'>Verticle Agriculture System - VAS</p>
                <p>VAS is the first stackable aeroponic agricultural system. The system is compatible with root-based crops such as potato, carrot, and various Chinese medicinal plants. With VAS, users can easily build and scale up their indoor farms, expanding the diversity of crops they can cultivate.</p>
            </div>
            <div className="Mobile-Product">
                <img src={"MP.jpg"} alt="MP" className=""></img>
                <p className='title'>Moss Power Battery - MP</p>
                <p>MP is a microbio fuel cell designed to absorb CO2, generate power, and produce freshwater simultaneously. Users can deploy it on rooftops, making it suitable for those aiming to reduce their carbon footprint and contribute to an eco-friendly environment.</p>
            </div>
            <div className="Mobile-Product">
                <p className='title'>More to Come in the future</p>
                <p>Our team will continue to develop until all problems are solved.</p>
            </div>
        
            </div>
            <h1 className="Mobile-subtitle">Meet Our Team</h1>
            <div className="Mobile-Block" ref={this.props.Memberref}>
            <div className="Mobile-Profile">
                <img src={"Tiger.jpg"} alt="Tger" className=""></img>
                <p className='title'>Tiger Cheng</p>
                <p>Technical Development</p>
                <p>Background in Electronics Designing, Mechanical and Software Development</p>
            </div>
            <div className="Mobile-Profile">
                <img src={"Wan.png"} alt="Wan" className=""></img>
                <p className='title'>Edward Wan</p>
                <p>Business Development</p>
                <p>Experienced in Business Management</p>
            </div>
            <div className="Mobile-Profile">
                <img src={"Kelvin.jpg"} alt="Kelvin" className=""></img>
                <p className='title'>Kelvin Wong</p>
                <p>Research Development</p>
                <p>Background in Material Science</p>
            </div>
            </div>
        
            <h1 className="Mobile-subtitle">Contact Us</h1>
            <div className="Mobile-Block" ref={this.props.Contactref} >
            <p>If you have any further queries or interest, please do not hesitate to send your inquiries by email or directly calling us!</p>
            <p>Email: <a href="mailto:factgie@gmail.com">factgie@gmail.com</a></p>
            <p>Tel: +852 57431032</p>
            </div>
        
        </div>
        );
    }   

}


