import React, {useEffect, useState} from 'react';
import {StyleSheet, Text, TextInput, Pressable} from 'react-native';
import { useNavigate } from 'react-router-dom';

import '@blueprintjs/core/lib/css/blueprint.css';

import {DynamoDBClient, ListTablesCommand} from "@aws-sdk/client-dynamodb";
import {fromCognitoIdentityPool} from '@aws-sdk/credential-providers';
import {CognitoUserPool, CognitoUser, AuthenticationDetails,CognitoUserAttribute} from "amazon-cognito-identity-js";
import * as AWS from 'aws-sdk/global';
import { Button } from "@blueprintjs/core";



const Style = StyleSheet.create({
    Container:{
        backgroundColor: 'white',
        minHeight: '600px',
        width: 'max-content',
        overflow: 'hidden',
        alignSelf: 'center',  
        marginLeft: 'auto',
        marginRight: 'auto',   
        display:'block',
        transition: 'transform 0.6s, opacity 1.2s',
    },
    FrontSide:{   
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        transition: 'opacity 1.2s',
    },
    BackSide:{
        opacity: 0,
        display:'none',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: '50px',
        transition: 'opacity 1.2s',
    },
    Disable:{
        height: '0px',
        flexDirection: 'column',
        // overflow: 'hidden',
        paddingTop: '0px',
        display: 'none'
    },
    Collape:{
        width: '100%',
        marginLeft: "0px",
        marginRight: "0px",
    },
    TextInput:{
        backgroundColor:'#b7c9bc',
        alignSelf:'center',
        marginbottom: '10px',
        height: '30px',
        width: '70%',
        fontsize: '18px'
    },
    TextInput_short:{
        backgroundColor:'#b7c9bc',
        alignSelf:'center',
        marginbottom: '10px',
        height: '25px',
        width: '60%',
        fontsize: '18px'
    },
    SubmitButton:{
        borderColor: '#c7c0b3',
        marginTop: '15px',
        marginBottom: '10px',
        backgroundColor: '#e3e3e3',
        alignSelf:'center',
 
    },
    Icon: {
        width: '150px',
        marginBottom: '30px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    Text: {
        // textAlign:'left'
        marginTop: '10px',
        marginBottom: '10px'
    },
    SmallText: {
        // textAlign:'left'
        margin: '0px',
        fontsize: '10px',
        alignSelf: 'center',
        fontStyle: 'normal',
        fontWeight: '700',
        marginBottom: '5px'

    },
    Line: {
        height: '1px',
        color: 'grey',
        width: '100%',
        backgroundColor: 'grey',
        opacity: '0.4',
        margin: 'auto'
    
    },
    ForgetPw: {
        alignSelf:'center'
    },

    ErrorMsg:{
        fontSize: '10px',
        color: 'red',
    },
    form:{
        width: '100%',        
        minHeight:'350px',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
    },
    button:{
        backgroundColor: '#4CAF50',
        border: 'none',
        color: 'white',
        testAlign: 'center',
        display: 'inline-block',
        fontSize: '16px',
        cursor: 'pointer'
    },
    LoginImg:{
        width:'100px',
        height:'100px',
    },
    li_before:{
        color: 'DimGrey',
        fontSize:'small',
    },
    li_after:{
        color: 'green',
        fontSize:'small',
    }, 
    SentAgain:{
        color: '#535556',
        marginTop:'5px',
        marginBottom:'5px',
    },


})
 
const Btn_OverIn = (event) => {
    // console.log(event)
    document.getElementById('btnLogin').style.backgroundColor = '#bdbdbd';
}

const Btn_OverOut = (event) => {
    // console.log(event)
    document.getElementById('btnLogin').style.backgroundColor = '#e3e3e3';
}

const LoginCall = (user,pw) => new Promise((onFulfilled, onRejected) => {
    const userPool = new CognitoUserPool({
        UserPoolId: 'ap-northeast-1_qpTjqfDTh',
        ClientId: 'u4nl2ljnusb2p1e4mbrbpdp7o'
        /*ClientId: 'u4nl2ljnusb2p1e4mbrbpdp7o'*/
    })
    const authenticationDetails  = new AuthenticationDetails({
        Username: user,
        Password: pw
    })
    const cognitoUser = new CognitoUser({
        Username: user,
        Pool: userPool,
    })

    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
            onFulfilled(result)
            console.log("testing auth user T")
        },
        onFailure: (err) => {
            onRejected(err)
            console.log("testing auth user F")
        },
        newPasswordRequired: (err) => {
            onRejected(err)
            console.log("testing auth USER new")
            newPassChange(user,pw);
        }
    })
});

const newPassChange= (user,pw) => new Promise((resolve, reject) => {
    const userPool = new CognitoUserPool({
        UserPoolId: 'ap-northeast-1_qpTjqfDTh',
        ClientId: 'u4nl2ljnusb2p1e4mbrbpdp7o'
    })
    const cognitoUser = new CognitoUser({
        Username: user,
        Pool: userPool,
    })

    let BackSide = document.getElementById('BackSide');
    Object.assign(BackSide.style,  {
        //transform: 'rotateY(180deg)',
        display:'flex'
    })  
    let Stage2  = document.getElementById('Stage2');
            Object.assign(Stage2.style, {
                display:'none',
                opacity: 0
    }) 

    let newPassword= document.getElementById('newpass');

    cognitoUser.changePassword('pw', newPassword, function(err, result) {
        if (err) {
            alert(err.message || JSON.stringify(err));
            return;
        }
        console.log('call result: ' + result);
        BackToSignIn();
    });
})


const SendVerificationCode = (user,password,vericode,confirmpass) =>  {
    const userPool = new CognitoUserPool({
        UserPoolId: 'ap-northeast-1_qpTjqfDTh',
        ClientId: 'u4nl2ljnusb2p1e4mbrbpdp7o'
    })
    const cognitoUser = new CognitoUser({
        Username: user,
        Pool: userPool,
    });

    cognitoUser.forgotPassword({
        onSuccess: function(data) {
            // successfully initiated reset password request
            console.log('CodeDeliveryData from forgotPassword: ' + data);
            /* let block = document.getElementById('block');
            Object.assign(block.style, {
                height:'200px'
            }) */
            let Stage2  = document.getElementById('Stage2');
            Object.assign(Stage2.style, {
                display:'none',
                opacity: 0
            })
            let Stage3= document.getElementById('Stage3');
            Object.assign(Stage3.style, {
                transition: 'height 0.2s',
                display: 'flex',
            })
            PopUpNotice('Send Verification Code Successfully!')
        },
        onFailure: function(err) {
            alert(err.message || JSON.stringify(err));
        },
    });
    
}

const VerifyCodeandResetPass = (user,password,isValid,confirmpass,vericode,errorMessage) => new Promise((resolve, reject) => {
    
    const userPool = new CognitoUserPool({
        UserPoolId: 'ap-northeast-1_qpTjqfDTh',
        ClientId: 'u4nl2ljnusb2p1e4mbrbpdp7o'
    })
    const cognitoUser = new CognitoUser({
        Username: user,
        Pool: userPool,
    });

    //const code=document.getElementById('vericode').value;
   console.log("pass",password,"compass",confirmpass)
    if(password===confirmpass){
        cognitoUser.confirmPassword(vericode, password, {
            onSuccess() {
                console.log('Password confirmed!');
                PopUpNotice('Password Reset Successfully!')
                password="";vericode="";user="";confirmpass="";
                BackToSignIn();
            },
            onFailure(err) {
                console.log('Password not confirmed!');
                console.log(err);
            },
        }); 

    }else{
        PopUpNotice('Password and Confirm Password Field do not match!')
    }
})

const SubmitLoginForm = (e,user,pw,Login_Callback,Profile_Callback,err) => {
    (async () => {
        LoginCall(user,pw)
        .then(
            (onFulfilled) => {
                AWS.config.region = 'ap-northeast-1';
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    region: 'ap-northeast-1',
                    IdentityPoolId: 'ap-northeast-1:eac26568-1de0-484a-a988-fe064ddc9646',
                    Logins: {
                        'cognito-idp.ap-northeast-1.amazonaws.com/ap-northeast-1_qpTjqfDTh': onFulfilled.getIdToken().getJwtToken(),
                    }

                });
                console.log('token',onFulfilled.getIdToken().getJwtToken())
                AWS.config.credentials.refresh(
                    (error) => {
                        if (error){
                            console.error(error);
                        } else {
                            console.log("Logined");
                            const client = new DynamoDBClient({
                                region: 'ap-northeast-1',
                                credentials: AWS.config.credentials
                            });
                            Profile_Callback(client);
                            Login_Callback("True");
                            console.log("DynamoDBClient region",client.region)
                            console.log("DynamoDBClient credentials",client.credentials)
                        }
                    }
                )
            },
            (onRejected) => {
                console.log(onRejected)
                let msg = onRejected.toString();
                msg = msg.split(':');
                err(msg[1]);
            }
        )
    })();
}

const IconBackHome = (callback) => {
    // console.log(callback);
    callback(-1)
}

const ForgetPw = (setUser) => {
    document.getElementById('user').value="";
    /* let Container = document.getElementById('Container');
    Object.assign(Container.style,  {  
        transform:  'rotateY(180deg)',
    }) */
    let FrontSide = document.getElementById('FrontSide');
    Object.assign(FrontSide.style,  {
        display:'none',
        opacity: 0
    })
    let BackSide = document.getElementById('BackSide');
    Object.assign(BackSide.style,  {
        //transform: 'rotateY(180deg)',
        display:'flex'
    })   
    /* let block = document.getElementById('block');
    Object.assign(block.style, {
        height:'200px'
    }) */
    setTimeout(() => {
        Object.assign(BackSide.style,  {
            opacity: 1,
        })
    },0.5)
} 

const BackToSignIn = () => {

    let Container = document.getElementById('Container');
    Object.assign(Container.style,  {
        transition: 'transform 0.6s',    
        transform:  'rotateY(0deg)',
    })
    let FrontSide = document.getElementById('FrontSide');
    Object.assign(FrontSide.style,  {
        display:'flex',
    })
    let BackSide = document.getElementById('BackSide');
    Object.assign(BackSide.style,  {
        // transform-delay:
        //transform: 'rotateY(180deg)',
        display:'none',
        opacity: 0
    })
    setTimeout(() => {
        Object.assign(FrontSide.style,  {
            opacity: 1,
        })
    },0.5)
}

const PopUpNotice = (obj) => {
    alert(obj);
}

/* const validate = (value,setErrorMessage,setIsValid,setPassword,setConfirmpass,password,errorMessage) => {
    var count=4;
    console.log("Check",value, value.length);
    console.log("value lenght",value.length<8);
    //console.log("check pass_O",password);
    //console.log("errorMessage",error)
    //console.log("password_O",password)
    if(value.length<8){
        //setErrorMessage((current) =>[...errorMessage, '8-character minimum length']);
        setErrorMessage([...errorMessage,"8-character minimum length"]);
        console.log('8-character minimum length');
        count=count-1;
        //error.concat('8-character minimum length');
    }
    if(!(/[a-z]/.test(value))){
        //setErrorMessage(current =>[...errorMessage, 'Contains at least 1 lowercase letter']);
        setErrorMessage([...errorMessage,"Contains at least 1 lowercase letter"]);
        console.log('Contains at least 1 lowercase letter');
        count=count-1;
        //error.concat('Contains at least 1 lowercase letter');
    }

    if(!(/[A-Z]/.test(value))){
         //setErrorMessage(current =>[...errorMessage, 'Contains at least 1 uppercase letter']);
         setErrorMessage([...errorMessage,"Contains at least 1 uppercase letter"]);
         console.log('Contains at least 1 uppercase letter');
         count=count-1;
         //error.concat('Contains at least 1 uppercase letter');
    }
       

    if(!(/\d/.test(value))){
        //setErrorMessage(current =>[...errorMessage, 'Contains at least 1 number']);
        setErrorMessage([...errorMessage,'Contains at least 1 number']);
        console.log('Contains at least 1 number');
        count=count-1;
        //error.concat('Contains at least 1 number');
    }
    if(count===4){
        setPassword(value);
        setIsValid(true);
    }else{
        setIsValid(false)
    }
    console.log("count",count)
    console.log("errorMessage",errorMessage)
} */

export default function LoginBox(props) {
    
    const [user,setUser] = useState("");
    const [pw,setPw] = useState("");
    const [confirmpass,setConfirmpass] = useState("");
    const [vericode,setVericode] = useState("");
    const [Error, setError] = useState(" ");
    const [reSend, setReSend] = useState(" ");
    const [password, setPassword] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [errorM, setErrorM] = useState('')

    const Navigator = useNavigate();

    useEffect(() => {
        // console.log(props)
    });
    
    //OLD ONE
   /*  return(
        <div style={Style.Container} id="Container" className="login-page-container">
            
            <div style={Style.FrontSide} id="FrontSide">
                <Pressable onPress={() => IconBackHome(Navigator)}>
                    <img style={Style.Icon} alt="Icon" src="logo_horizontal.png"/>
                </Pressable>
                <p style= {Style.Text}> User Email</p>
                <TextInput style={Style.TextInput} onChangeText={setUser}/>
                <p style= {Style.Text}> Password</p>
                <TextInput style={Style.TextInput} onChangeText={setPw} secureTextEntry={true}/>
                <Button 
                    id = "btnLogin"
                    icon="log-in" 
                    style={Style.SubmitButton}
                    text="Login"
                    onClick = {(e) => SubmitLoginForm(e,user,pw,props.Login_Callback,props.User_Callback,setError)}
                    onMouseEnter = {(event) => Btn_OverIn(event)}
                    onMouseLeave = {(event) => Btn_OverOut(event)}
                />
                <Text style={Style.ErrorMsg}>{Error}</Text>
                <hr style={Style.Line}/>
                <Pressable style={Style.ForgetPw} onPress={() =>ForgetPw(setUser)}>
                    <div>Forgot Password?</div>
               </Pressable> 
            </div>
           <div style={Style.BackSide} id="BackSide">
                <Pressable onPress={() => IconBackHome(Navigator)}>
                    <img style={Style.Icon} alt="Icon" src="logo_horizontal.png"/>
                </Pressable>
                <div style={Style.form} id='block'>
                    <div id = "Stage2"> 
                        <p style= {Style.Text}> Enter Email for reset Password</p>
                        <TextInput id="user" style={StyleSheet.flatten([Style.TextInput,Style.Collape])} onChangeText={setUser}/>
                    
                        <Button
                            style= {Style.button}
                            onClick = {() =>SendVerificationCode(user)}
                            onMouseEnter = {(event) => Btn_OverIn(event)}
                            onMouseLeave = {(event) => Btn_OverOut(event)}
                        >Confirm</Button>   
                    </div>
                        <div style= {Style.Disable} id="Stage3">
                            <div style= {Style.requestInputBlock} >
                                <p style= {Style.SmallText}> Enter Password Reset Code</p>
                                <TextInput id='vericode' value={vericode} style={StyleSheet.flatten([Style.TextInput,Style.Collape])} onChangeText={setVericode}/>
                                <div id="Pop-resend" style={Style.popUp}>
                                    <Pressable style={Style.ForgetPw} onPress={() => {SendVerificationCode(user)}}>
                                        <div id="reSend">Didn't Receive? Send Again</div>
                                    </Pressable>
                                </div>
                            </div>
                            <div >
                            <div style= {Style.requestInputBlock}>
                                <p style= {Style.SmallText}> Enter New Password</p>
                                <TextInput type="password" id='password' style={StyleSheet.flatten([Style.TextInput,Style.Collape])} onChangeText={setPassword}/> 
                                {isValid ? (<p style={{ color: 'green' }}>Password is valid!</p>): (undefined)} 
                                    <ul style={{listStyleType: 'none'}}>
                                    {password.length<8?(<li style={Style.li_before}>8-character minimum length</li>):(<li style={Style.li_after}>&#10004; 8-character minimum length</li>)}
                                    {!(/[a-z]/.test(password))?(<li style={Style.li_before}>Contains at least 1 lowercase letter</li>):(<li style={Style.li_after}>&#10004; Contains at least 1 lowercase letter</li>)}
                                    {!(/[A-Z]/.test(password))?(<li style={Style.li_before}>Contains at least 1 uppercase letter</li>):(<li style={Style.li_after}>&#10004; Contains at least 1 uppercase letter</li>)}
                                    {!(/[0-9]/.test(password))?(<li style={Style.li_before}>Contains at least 1 number</li>):(<li style={Style.li_after}>&#10004; Contains at least 1 number</li>)}
                                    </ul>
                                <p style= {Style.SmallText}> Enter New Password Again</p>
                                <TextInput type="password"  id='confirmpass' style={StyleSheet.flatten([Style.TextInput,Style.Collape])} onChangeText={setConfirmpass}/>
                                
                                <Button 
                                    style= {Style.button}
                                    id = "btnReset"
                                    icon="nest" 
                                    //style={Style.SubmitButton}
                                    text="Next"
                                    onClick = {(e) => {VerifyCodeandResetPass(user,password,isValid,confirmpass,vericode,errorM)}}
                                    onMouseEnter = {(event) => Btn_OverIn(event)}
                                    onMouseLeave = {(event) => Btn_OverOut(event)}
                                />
                            </div>
                        </div>
                        </div>
                        
                </div>
                <hr style={Style.Line}/>
                <Pressable style={Style.ForgetPw} onPress={() => BackToSignIn()}>
                    <div>Sign In</div>
                </Pressable>

            </div>
        </div>
    ) */

    return (
        <div className="login-page-container">
          <div id="Container" className="login-page-container1">
            <div className="login-page-container2">
              <img
                alt="login_pic"
                src="login_pic.jpg"
                className="login-page-image"
              />
            </div>
            {/* login */}
            <div className="login-page-container3 " style={Style.FrontSide} id="FrontSide">
              <section className="login-page-container4 ">
                <div className="login-page-container5">
                  <img
                    alt="logo"
                    src="logo_horizontal.png"
                    className="login-page-image1"
                  />
                </div>
                <div className="login-page-container6" >
                  <label className="login-page-text">User Email</label>
                  
                  <TextInput style={Style.TextInput} onChangeText={setUser}/>
                  <label className="login-page-text1">Password</label>
                  <TextInput style={Style.TextInput} onChangeText={setPw} secureTextEntry={true}/>

                  <Button 
                    id = "btnLogin"
                    icon="log-in" 
                    style={Style.SubmitButton}
                    text="Login"
                    onClick = {(e) => SubmitLoginForm(e,user,pw,props.Login_Callback,props.User_Callback,setError)}
                    onMouseEnter = {(event) => Btn_OverIn(event)}
                    onMouseLeave = {(event) => Btn_OverOut(event)}
                  />
                  <p className="login-page-text2">
                    <span>----------------------------</span>
                    <br></br>
                  </p>
                  <Pressable style={Style.ForgetPw}  onPress={() =>ForgetPw(setUser)}>
                    <p className="login-page-text5">Forgot Password?</p>
                  </Pressable> 
                  
                </div>
                
                
              </section>
            </div>
            {/* ResetPass */}
            <div className="login-page-container3" style={Style.BackSide} id="BackSide">
              <section className="login-page-container4">
                <div className="login-page-container5">
                  <img
                    alt="logo"
                    src="logo_horizontal.png"
                    className="login-page-image1"
                  />
                </div>
                <div id='block'>
                    {/* Set Email & GetCode */}
                    <div className="login-page-container6" id = "Stage2">
                        <label className="login-page-text">Enter Email for reset Password</label>
                        <TextInput id="user"  style={Style.TextInput}  onChangeText={setUser}/>
                        <Button 
                            style={Style.SubmitButton}
                            onClick = {() =>SendVerificationCode(user)}
                            onMouseEnter = {(event) => Btn_OverIn(event)}
                            onMouseLeave = {(event) => Btn_OverOut(event)}
                        >Confirm</Button>   
                        <p className="login-page-text2">
                            <span>----------------------------</span>
                            <br></br>
                        </p>
                        <Pressable style={Style.ForgetPw} onPress={() =>BackToSignIn()}>
                            <p className="login-page-text5">Sign In</p>
                            <div></div>
                        </Pressable>

                    </div>

                    {/* Reset Pass */}
                    <div className="login-page-container6" style= {Style.Disable} id = "Stage3">
                        <div style= {Style.requestInputBlock} >
                            <label style= {Style.SmallText}> Enter Password Reset Code </label><p></p>
                            <TextInput id='vericode' value={vericode} style={Style.TextInput_short}onChangeText={setVericode}/>
                            <div id="Pop-resend" style={Style.popUp}>
                                <Pressable style={Style.ForgetPw} onPress={() => {SendVerificationCode(user)}}>
                                    <div id="reSend" style={Style.SentAgain}>Didn't Receive? Send Again</div>
                                </Pressable>
                            </div>
                        </div>

                        <div style= {Style.requestInputBlock} >
                                <label style= {Style.SmallText}> Enter New Password </label><p></p>
                                    <TextInput type="password" id='password' style={Style.TextInput_short}onChangeText={setPassword}/> 
                                    {isValid ? (<p style={{ color: 'green' }}>Password is valid!</p>): (undefined)} 
                                        <ul style={{listStyleType: 'none'}}>
                                        {password.length<8?(<li style={Style.li_before}>8-character minimum length</li>):(<li style={Style.li_after}>&#10004; 8-character minimum length</li>)}
                                        {!(/[a-z]/.test(password))?(<li style={Style.li_before}>Contains at least 1 lowercase letter</li>):(<li style={Style.li_after}>&#10004; Contains at least 1 lowercase letter</li>)}
                                        {!(/[A-Z]/.test(password))?(<li style={Style.li_before}>Contains at least 1 uppercase letter</li>):(<li style={Style.li_after}>&#10004; Contains at least 1 uppercase letter</li>)}
                                        {!(/[0-9]/.test(password))?(<li style={Style.li_before}>Contains at least 1 number</li>):(<li style={Style.li_after}>&#10004; Contains at least 1 number</li>)}
                                        </ul>
                            
                                    <label style= {Style.SmallText}>Enter New Password Again</label><p></p>
                                    <TextInput type="password"  id='confirmpass' style={Style.TextInput_short} onChangeText={setConfirmpass}/>
                                    <p></p>
                                    <Button 
                                        style={Style.SubmitButton}
                                        id = "btnReset"
                                        icon="nest" 
                                        onClick = {(e) => {VerifyCodeandResetPass(user,password,isValid,confirmpass,vericode,errorM)}}
                                        onMouseEnter = {(event) => Btn_OverIn(event)}
                                        onMouseLeave = {(event) => Btn_OverOut(event)}
                                    >Next</Button>  
                                    <p className="login-page-text2_short">
                                        <span>----------------------------</span>
                                        <br></br>
                                    </p>
                                    <Pressable style={Style.ForgetPw} onPress={() =>BackToSignIn()}>
                                        <p className="login-page-text5">Sign In</p>
                                        <div></div>
                                    </Pressable>
                        </div>
                    </div>
                </div>  
              </section>
            </div>
          </div>
        </div>
    )   
}