import React from 'react';
import './CSS/Web.css';
import { useNavigate } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import 'bootstrap/dist/css/bootstrap.css';




const handleClick = (target) => {
    // console.log(target);
        target.current?.scrollIntoView(true ,{behavior: 'smooth'});
        HiddenMobileMenu();
    }

const BackToTop = () => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
    HiddenMobileMenu();
}

const navigateTo = (Navigator,Destination) => {
    // console.log(Destination);
    Navigator(Destination);
}

const sendEmail=()=> {
    const fullName = document.getElementById('fullName').value;
    const senderEmail = document.getElementById('senderEmail').value;
    const message = document.getElementById('message').value;
    const emailContent = `Full Name: ${fullName}\nSender Email: ${senderEmail}\nMessage: ${message}`;
    const subject = encodeURIComponent('New Message from Contact Form');
    const body = encodeURIComponent(emailContent);

    const mailAppTimeout = setTimeout(() => {
        // Timeout: Mail app did not open within the specified time
        handleTimeoutAction();
      }, 5000); // 5 seconds

  
    window.location.href = `mailto:factgie@gmail.com?subject=${subject}&body=${body}`;

    function handleTimeoutAction() {
        alert('No mail app is available. Please manually open your email client and compose a new email to example@example.com.');
      }
    
      function clearMailAppTimeout() {
        clearTimeout(mailAppTimeout);
      }
    
      window.addEventListener('blur', clearMailAppTimeout);
}
const ShowMobileMenu=()=>{
    document.getElementById("mobilemenu").style.display="block";
}
const HiddenMobileMenu=()=>{
    document.getElementById("mobilemenu").style.display="none";
}

export default function WindowPage(props) {  
    const Navigator = useNavigate();
    

    return (
        // <React.Fragment>
        <div className="home-container">
            <div className="home-container01">
                <div className="home-first" >
                <img
                    alt="image1"
                    src="f.jpg"
                    className="home-image"
                />
                <header data-thq="thq-navbar" className="home-navbar-interactive">
                    <img
                    alt="logo"
                    src="logo_horizontal.png"
                    className="home-image1"
                    />
                    <div data-thq="thq-navbar-nav" className="home-desktop-menu">
                    <nav className="home-links">
                        <button className="home-text" onClick={() => BackToTop()}>Home</button>
                        <button className="home-text01" onClick={() => handleClick(props.Productref)}>Products</button>
                        <button className="home-text02"onClick={() => handleClick(props.Memberref)}>Meet Our Team</button>
                        <button className="home-text03"onClick={() => handleClick(props.Contactref)}>Contact Us</button>
                    </nav>
                    <div className="home-buttons">
                        <button className="home-login button" onClick={() => navigateTo(Navigator,"/DashBoard")} >Login</button>
                    </div>
                    </div>
                    <div data-thq="thq-burger-menu" className="home-burger-menu">
                    <svg viewBox="0 0 1024 1024" className="home-icon" onClick={()=>ShowMobileMenu()}>
                        <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                    </svg>
                    </div>
                    <div id="mobilemenu" data-thq="thq-mobile-menu" className="home-mobile-menu">
                    <div className="home-nav">
                        <div className="home-top">
                        <img
                            alt="image2"
                            src="logo_horizontal.png"
                            className="home-logo"
                        />
                        <div data-thq="thq-close-menu" className="home-close-menu">
                            <svg viewBox="0 0 1024 1024" className="home-icon02" onClick={()=>HiddenMobileMenu()}>
                            <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                            </svg>
                        </div>
                        </div>
                        <nav className="home-links1">
                        <span className="home-text04" onClick={() => BackToTop()}>About Us</span>
                        <span className="home-text05" onClick={() => handleClick(props.Productref)}>Products</span>
                        <span className="home-text06" onClick={() => handleClick(props.Memberref)}>Team</span>
                        <span className="home-text07">
                            <span className="home-text08" onClick={() => handleClick(props.Contactref)}>Contact Us</span>
                            <br></br>
                            <br></br>
                        </span>
                        </nav>
                        <div className="home-buttons1">
                        <button onClick={() => navigateTo(Navigator,"/DashBoard")} className="home-login1 button">Login</button>
                        </div>
                    </div>
                    <div>
                        <svg
                        viewBox="0 0 950.8571428571428 1024"
                        className="home-icon04"
                        >
                        <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                        </svg>
                        <svg
                        viewBox="0 0 877.7142857142857 1024"
                        className="home-icon06"
                        >
                        <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                        </svg>
                        <svg
                        viewBox="0 0 602.2582857142856 1024"
                        className="home-icon08"
                        >
                        <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                        </svg>
                    </div>
                    </div>
                </header>
                <div className="home-container02">
                    <h1 className="home-text11">
                        <TypeAnimation
                            sequence={[
                            "Welcome to Factgie ",
                            1000,
                            "",
                            1000,
                            ]}
                            speed={50}
                            cursor={true}
                            repeat={Infinity}
                        />
                        
                
                    </h1>
                    <span className="home-text12">
                    As an agricultural technology company, we are committed 
                    to combatting world hunger and promoting sustainable environmental
                    practices. Our vision is to revolutionize agriculture, empowering
                    farmers with cutting-edge technologies for increased productivity
                    and resource management.
                    </span>
                </div>
                <div className="home-second" ref={props.Productref}>
                <div className="home-product">
                    <div className="home-container03">
                    <div className="home-container04">
                        <h1 className="home-text13">Our Products</h1>
                        <span className="home-text14">
                        <span>
                            <span className="product_text"> 
                            Please stay connected with us. We will continue developing more exceptional products in the future.
                            </span>
                        </span>
                        </span>
                    </div>
                    <div className="home-container05">
                        <div className="home-container07 box">
                        <span className="home-text19">Verticle Agriculture System - VAS</span>
                        <img alt="image4" src="VAS.png" className="home-image3" />
                        {/* <button className="home-button1 button" onClick={() => navigateTo(Navigator,"./Product1")}>Learn More</button> */}
                        </div>

                        <div className="home-container07 box">
                        <span className="home-text19">Moss Power Battery - MP</span>
                        <img alt="image4" src="MP.jpg" className="home-image3 home-image4" onClick={() => navigateTo(Navigator,"./Product1")}/>
                        {/* <button className="home-button1 button" onClick={() => navigateTo(Navigator,"./Product2")}>Learn More</button> */}
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="home-third" ref={props.Memberref}>
                    <h1 className="home-heading ">Meet Our Team</h1>
                    <div className="home-teams">
                        <div className="container">
                            <div className="row">
                                <div className="col col_c">
                                    <div className="our-team"><img src="TIGER_1.jpg" alt=""/>
                                        <div className="team-content"><br></br>
                                            <h3 className="title ">Tiger Cheng</h3><br></br>
                                            <div className="post">Technical Development</div><br></br>
                                            <div className="post">Background in Electronics Designing, Mechanical and Software Development</div>
                                        </div>
                                    </div>
                                </div>
                        
                                <div className="col col_c">
                                    <div className="our-team"><img src="WAN_1.jpg" alt=""/>
                                        <div className="team-content"><br></br>
                                            <h3 className="title">Edward Wan</h3><br></br>
                                            <div className="post">Business Development</div><br></br>
                                            <div className="post">Experienced in Business Management</div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col col_c">
                                    <div className="our-team"> <img src="Kelvin.jpg" alt=""/>
                                        <div className="team-content"><br></br>
                                            <h3 className="title">Kelvin Wong</h3><br></br>
                                            <div className="post">Research Development</div><br></br>
                                            <div className="post">Background in Material Science</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
                <div className="home-fourth" ref={props.Contactref}>
                <div className="home-container08">
                    <h1 className="home-text20">Contact Us</h1>
                </div>
                <div className="home-container09">
                    <div className="home-container10">
                    <form className="home-form" action="mailto:winnieauyeung177@gmail.com">
                        <label className="home-text21" >Your Name :</label>
                        <input type="text" className="home-textinput input" autocomplate="off" id="fullName"/>
                        <label className="home-text22">Your Email :</label>
                        <input type="email" className="home-textinput1 input" autocomplate="off" id="senderEmail" />
                        <label className="home-text23" required>Message:</label>
                        <textarea
                        rows="10"
                        className="home-textarea textarea"
                        autocomplate="off"
                        id="message" 
                        ></textarea>
                        <button type="button" className="home-button2 button" onClick={() => sendEmail()}>
                        <span className="home-text24">
                            <span>Submit</span>
                            <br></br>
                        </span>
                        </button>
                    </form>
                    </div>

                    
                    <div className="home-container11">
                    <h1 className="home-text27">Call Us</h1>
                    <span className="home-text28">+852 57431032</span>
                    <h1 className="home-text29">Email Us</h1>
                    <span className="home-text30"><a className="emailurl" href="mailto:factgie@gmail.com">factgie@gmail.com</a></span>
                    <span className="home-text31">
                        If you have any further queries or interest, please do not
                        hesitate to send your inquiries by email or directly calling us!
                    </span>
                    </div>
                </div>
                </div>

            </div>
            <div className="footer-basic">
                <footer>
                    
                    <p className="copyright">© Copyright 2023 Factgie. All rights reserved</p>
                    
                </footer>
            </div>
        </div>
        </div>
        //</React.Fragment>
    );  
}
