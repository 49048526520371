import '@blueprintjs/core/lib/css/blueprint.css';

import React from 'react';
import {StyleSheet, Pressable} from 'react-native';
import { Alignment, Button } from "@blueprintjs/core";
import { Line } from "react-chartjs-2";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import AWS from 'aws-sdk';
import Select from 'react-select'
import { faker } from '@faker-js/faker';
import zoomPlugin from 'chartjs-plugin-zoom';
import {Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend,} from 'chart.js';



ChartJS.register(CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend,zoomPlugin);

const SideBar_Styles = StyleSheet.create({
    MainContainer:{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        backgroundColor: '#938e85',
        height: '100%',
        padding: '20px'
    },
    Icon:{
        width: '150px',
        marginBottom: '30px',
        marginTop: '20px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    Line: {
        height: '1px',
        color: 'grey',
        width: '100%',
        backgroundColor: 'grey',
        opacity: '0.4'
    },
    SubmitButton:{
        borderColor: '#c7c0b3',
        marginTop: '10px',
        marginBottom: '10px',
        backgroundColor: '#e3e3e3',
        width:'auto' 
    },
    PageBut_b:{
        color: '#ffffff',
        backgroundColor: '#1c1b1b'
    },
    PageBut:{
        color: 'black',
        margin: '15px',
        fontsize: '20px',
        fontWeight: '700',
        backgroundColor: '#ffffff'
    },

    

})

const PageStyles = StyleSheet.create({
    MainPage: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
    },
    ContentDisplay:{
        flex: 5,
    },
    
})

  

//Chart Data
export const options = {
    type: 'line',
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        //text: 'Table name', 
      },
      zoom: {
        zoom: {
          wheel: {enabled: true},
          pinch: {enabled: true},
          mode: 'xy',
          scaleMode: 'xy',
          pan: {
            enabled: true,
            mode: 'xy',
          }, 
        }
      }
    },
};
export var labels=[1],tablelist=[],deviceID=[],deviceData=[],datayear=[],datamonth=[],dataday=[],timestamp,builttime;
export var startYear,currentYear,currentMonth,startMonth,EnableDateSelection;
export var CHARTDATA = {
    labels,
    datasets: [],
};


export default class CMS extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            MainWindow :"",
            n_tn:"",
            n_did:"",
            n_data:"",
            n_year:"",
            n_month:"",
            n_day:"",
            chartinfo_tn:{value:"",label:""},
            chartinfo_did:{value:"",label:""},
            chartinfo_year:{value:"",label:""},
            chartinfo_month:{value:"",label:""},
            chartinfo_day:{value:"",label:""},
            // chartinfo_data:{value:"",label:""},
            AWS:"",
            datapointlimit:0,
            display:"",
        }
        this.AWS = require("aws-sdk");
        this.AWS.config.update({region: "ap-northeast-1"});
    }
   async componentDidMount() {
        await this.getTablesDetails(this.state.chartinfo_tn.value);
        //await this.getdid();
    }

/*     Btn_OverIn = (btn) => {
        document.getElementById(btn).style.backgroundColor = '#bdbdbd';
    }
    
    Btn_OverOut = (btn) => {
        document.getElementById(btn).style.backgroundColor = '#e3e3e3';
    } */
    VerifyChartInfo(){
        if(tablelist.some(item => this.state.chartinfo_tn.value === item.name))return false;
        else if(deviceID.some(item => this.state.chartinfo_did.value === item.name))return false;
        //else if(deviceData.some(item => this.state.chartinfo_data.value === item.name)) return false;
        else return true;         
    } 
    sortDataBytime (data){
        return data.Items.sort((a,b)=>a.sample_time.N-b.sample_time.N)
    }
    async getTablesDetails(e){
        await this.setState({chartinfo_tn:e,MainWindow:"Data Visualize"})
        new AWS.DynamoDB({apiVersion: "2012-08-10"}).scan({
            TableName:"TablesDetails",
        },(err, data) => {
             if (err) {
                console.log(err, err.stack); 
             }else{
                //console.log(data)
                tablelist=[]
                for(const ele of data.Items){tablelist.push({'value':ele.TableName.S,'label':ele.TableName.S})} 
                this.setState({n_tn:tablelist.length})
                if(!this.state.chartinfo_tn)return;
                deviceID=[]
                for(const ele of data.Items){if(this.state.chartinfo_tn.value==ele.TableName.S)for(const e of ele.device_id.SS){deviceID.push({'value':e,'label':e})}
                this.setState({n_did:deviceID.length})
                //deviceData=[]
                /* for(const ele of data.Items){if(this.state.chartinfo_tn.value==ele.TableName.S)for(const e of ele.device_data.SS){if(e!=="Machine")deviceData.push({'value':e,'label':e})}}
                this.setState({n_data:deviceData.length}) */
             }
            }    
        }); 
    }
   /*  async GetYear_1(e){
        this.GetTime();
        datayear=[]
        await this.setState({chartinfo_did:e})
        //console.log("this.state.chartinfo_did",this.state.chartinfo_did)
        if(!this.VerifyChartInfo()){
            return;
        }
        else{
            var dbb=new AWS.DynamoDB({apiVersion: "2012-08-10"})
            dbb.scan({
                FilterExpression: "device_id = :filter",
                ExpressionAttributeValues: {
                    ":filter": {S:this.state.chartinfo_did.value},
                },
                ProjectionExpression: "sample_time",
                TableName:this.state.chartinfo_tn.value,
            },(err, sortedData) =>{
                 if (err) {
                    console.log(err, err.stack);
                 }else{
                    sortedData=this.sortDataBytime(sortedData)
                    timestamp=sortedData.map((item)=>(parseInt(item.sample_time.N)))
                    var Year=[...new Set(timestamp.map((item)=>(new Date(parseInt(item)).getFullYear())))];
                    for(const ele of Year){datayear.push({'value':ele,'label':ele})}
                    this.setState({n_year:Year.length})
                 } 
            });
        }   
    }
    async GetMonth_1(e){
        datamonth=[];
        await this.setState({chartinfo_year:e})
        //console.log("this.state.chartinfo_year",this.state.chartinfo_year)
        if(!this.VerifyChartInfo()){
            return;
        }
        else{
            var t = structuredClone(timestamp);
            t = t.filter(date => new Date(date).getFullYear() == this.state.chartinfo_year.value);
            let Month=[...new Set(t.map((item)=>(new Date(parseInt(item)).getMonth())))];
            for(let ele of Month)datamonth.push({'value':ele,'label':ele+1})
            this.setState({n_month:Month.length})
        }   
    }
    async GetDay_1(e){
        dataday=[];
        await this.setState({chartinfo_month:e})
        //console.log("this.state.chartinfo_month",this.state.chartinfo_month)
        if(!this.VerifyChartInfo()){
            return;
        }
        else{
            var t = structuredClone(timestamp);
            t = t.filter(date => new Date(date).getFullYear() == this.state.chartinfo_year.value);
            t = t.filter(date => new Date(date).getMonth() == this.state.chartinfo_month.value);
            (t.length>400)?(this.setState({datapointlimit:1})):(this.setState({datapointlimit:0}))
            if(t.length<=400)return  //did not show the selection for the date option
            var Day=[...new Set(t.map((item)=>(new Date(parseInt(item)).getDate())))];
            for(const ele of Day){dataday.push({'value':ele,'label':ele})}
            this.setState({n_day:Day.length})
        }   
    } */
    
    async GetYear(e){
        datayear=[]
        datamonth=[]
        dataday=[]
        await this.setState({chartinfo_did:e})
        //console.log("this.state.chartinfo_did",this.state.chartinfo_did)
        if(!this.VerifyChartInfo()){
            return;
        }
        else{
            var ddb = new AWS.DynamoDB({apiVersion: "2012-08-10"});
            ddb.scan({//sort by device_id
                FilterExpression: "TableName = :filter",
                ExpressionAttributeValues: {
                    ":filter": {S:this.state.chartinfo_tn.value},
                },
                ProjectionExpression: "builttime,EnableDaySelection",
                TableName:"TablesDetails",
            },(err, TimeData) =>{
                 if (err) {
                    console.log(err, err.stack); // an error occurred
                 }else{
                    builttime=parseInt(TimeData.Items[0].builttime.N);
                    console.log("TimeData.Items[0].EnableDaySelection",typeof TimeData.Items[0].EnableDaySelection.BOOL)
                    EnableDateSelection=TimeData.Items[0].EnableDaySelection.BOOL;
              
                    //console.log(new Date(parseInt(TimeData.Items[0].builttime.N)).getFullYear());
                    //console.log(new Date(parseInt(TimeData.Items[0].builttime.N)).getMonth());
                    var NOW = Date.now();
                    //console.log(new Date(NOW).getFullYear());
                    //console.log(new Date(start).getMonth());
                    startYear=new Date(parseInt(TimeData.Items[0].builttime.N)).getFullYear();
                    startMonth=new Date(parseInt(TimeData.Items[0].builttime.N)).getMonth();

                    currentYear=new Date(NOW).getFullYear();
                    console.log("this.state.n_year",startYear,"testing",startMonth)
                    console.log("this.state.n_year",currentYear)
                    var sy=startYear;

                    while(sy<=currentYear){
                       datayear.push({'value':sy,'label':sy});
                       sy++;
                    }
                    this.setState({n_year:datayear.length});
                    console.log("this.state.n_year",datayear.length)
                    console.log("this.state.n_year",this.state.n_year)
                 }
            });
        } 
    }

    async GetMonth(e){
        datamonth=[];
        dataday=[];
        await this.setState({chartinfo_year:e})
        //console.log("this.state.chartinfo_year",this.state.chartinfo_year)
        if(!this.VerifyChartInfo()){
            return;
        }
        else{
            startMonth=new Date(builttime).getMonth();
            var NOW = Date.now(),a;
            currentMonth=new Date(NOW).getMonth();
            currentMonth++;
            console.log("year",startYear,currentYear)
            console.log("month",startMonth,currentMonth)
            console.log(this.state.chartinfo_year.value)
            if(this.state.chartinfo_year.value==startYear){
                for(a=startMonth;a<12;a++) datamonth.push({'value':a,'label':a+1});
                console.log(11);
            }else if(this.state.chartinfo_year.value==currentYear){
                for(a=0;a<currentMonth;a++) datamonth.push({'value':a,'label':a+1});
                console.log(12);
            }else{
                for(a=0;a<12;a++) datamonth.push({'value':a,'label':a+1});
                console.log(13);
            }
            this.setState({n_month:datamonth.length});

            if(EnableDateSelection){
                console.log("checking enabledateselection")
                var dbb=new AWS.DynamoDB({apiVersion: "2012-08-10"})
                dbb.scan({
                    FilterExpression: "device_id = :filter",
                    ExpressionAttributeValues: {
                        ":filter": {S:this.state.chartinfo_did.value},
                    },
                    ProjectionExpression: "sample_time",
                    TableName:this.state.chartinfo_tn.value,
                },(err, sortedData) =>{
                    if (err) {
                        console.log(err, err.stack);
                    }else{
                        sortedData=this.sortDataBytime(sortedData)
                        timestamp=sortedData.map((item)=>(parseInt(item.sample_time.N)))
                    } 
                });
            }

         
            
            
        }   
    }
    getNumberOfDays(year, month) {
        // Create a new Date object with the specified year and month (months are zero-based)
        var date = new Date(year, month, 1);
        // Get the last day of the month by subtracting one day from the first day of the next month
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        return lastDay;
    }
    async GetDay(e){
        dataday=[];
        await this.setState({chartinfo_month:e})
        if(!this.VerifyChartInfo()){
            return;
        }
        else{
            if(EnableDateSelection){
            var t = structuredClone(timestamp);
            t = t.filter(date => new Date(date).getFullYear() == this.state.chartinfo_year.value);
            t = t.filter(date => new Date(date).getMonth() == this.state.chartinfo_month.value);
            (t.length>400)?(this.setState({datapointlimit:1})):(this.setState({datapointlimit:0}))
            if(t.length<=400)return  //did not show the selection for the date option
            var numberOfDays = this.getNumberOfDays(this.state.chartinfo_year.value, this.state.chartinfo_month.value);
            for(var a=0;a<numberOfDays;a++) dataday.push({'value':a,'label':a+1});
            this.setState({n_day: dataday.length})   
            }
        } 
    }
    async getdid(){
        //await this.setState({chartinfo_did:e})
            console.log("query")
            //console.log(this.state.chartinfo_tn.value)
            //console.log(e)
            new AWS.DynamoDB.DocumentClient({}).query({
                /*TableName :"VAS01_Env",
                 KeyConditions:{
                    "sample_time":{
                        ComparisonOperator:"GT",
                        AttributeValueList:[1]
                    }
                }, 
                 QueryFilter:{
                    "device_id":{
                    ComparisonOperator:"EQ",
                    AttributeValueList:[{"S":"VAS01"}]
                }},  */
            
                TableName :"VAS01_Env",
                KeyConditionExpression : "sample_time=:st AND device_id=:did",    //device_id=:did AND 
                ExpressionAttributeValues: {
                    ":st":1669820383016,
                    //":st":166000000000,
                    ":did":"VAS01"
                }, 
                ProjectionExpression: 'sample_time',
                //ScanIndexForward: true, //true: sort in ascending order 
            },).promise()
            .then(data=>console.log("query",data.Items))
            .catch(console.error)

            var ddb = new AWS.DynamoDB({apiVersion: '2012-08-10'});
            ddb.batchGetItem({
                RequestItems: {
                    'VAS01_Env': {
                      Keys: [
                        {'device_id':{S:'VAS01'}},
                        {'sample_time':{N:"1669820383016"}}
                      ],
                      ProjectionExpression:'device_id,sample_time,device_data',
                     // ConsistentRead: true
                    }
                  }
            },(err, data) =>{
                if (err) {
                  console.log("Error", err);
                } else {
                  data.Responses.VAS01_Env.forEach(function(element, index, array) {
                    console.log(element);
                  });
                }
            })

            



    }
    async getChartData(){
        if(!this.VerifyChartInfo()){
            return;
        }
        else{
            this.setState({display:"false"})
            var ddb = new AWS.DynamoDB({apiVersion: "2012-08-10"});
            ddb.scan({//sort by device_id
                FilterExpression: "device_id = :filter",
                ExpressionAttributeValues: {
                    ":filter": {S:this.state.chartinfo_did.value},
                },
                ProjectionExpression: "device_data,sample_time",
                TableName:this.state.chartinfo_tn.value,
            },(err, sortedData) =>{
                 if (err) {
                    console.log(err, err.stack); // an error occurred
                 }else{
                    console.log(this.state);
                    //Filter Data by the selection keys
                    sortedData=this.sortDataBytime(sortedData)
                    //console.log('sort',sortedData);
                    sortedData = sortedData.filter(item => new Date(parseInt(item.sample_time.N)).getFullYear() == this.state.chartinfo_year.value);
                    sortedData = sortedData.filter(item => new Date(parseInt(item.sample_time.N)).getMonth() == this.state.chartinfo_month.value);
                    if(this.state.chartinfo_day.value){
                        if(this.state.datapointlimit>0)sortedData = sortedData.filter(item => new Date(parseInt(item.sample_time.N)).getDate() == this.state.chartinfo_day.value);
                    }
                    CHARTDATA.labels=sortedData.map((item)=>(new Date(parseInt(item.sample_time.N))).toLocaleString('sv'))
                    sortedData=sortedData.map((item)=>item.device_data.M)

                    //Get Data by all the selection keys
                    if(sortedData.length<1)return
                    var tem,tt=[],TT=[],keys = Object.keys(sortedData[0]);
                    for(let a=0;a<keys.length;a++){
                        for(const e of sortedData){
                            keys = Object.keys(e);
                            tem=e[keys[a]]
                            tem===undefined||tem===null?(tt.push(0.00)):(tt.push(tem)) 
                        }
                        TT.push(tt);
                        tt=[];
                    }
                    console.log("TT",TT)
                    
                    //Assign Chart Data
                    CHARTDATA.datasets=[];
                    for(let a=0;a<TT.length;a++){
                        let r=Math.floor(Math.random() * 256),g=Math.floor(Math.random() * 256),b=Math.floor(Math.random() * 256);
                        if(CHARTDATA.datasets[a]===undefined)CHARTDATA.datasets.push({
                            label: 'Dataset',
                            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
                            borderColor: 'rgb(' +r+ ',' + g + ',' + b + ')',
                            backgroundColor: 'rgb(' + r+ ',' + g + ',' + b + 0.9+')',
                          },)
                        CHARTDATA.datasets[a].data=TT[a].map((item)=>item[Object.keys(item)]>200?(0.00):(item[Object.keys(item)]))
                    }
                    for(let a=0;a<CHARTDATA.datasets.length;a++)CHARTDATA.datasets[a].label=keys[a]+": "+TT[a].length

                    this.setState({display:"true"})

                 } 
            });
        }  

    }
    



    render() {
    return(
        <div className="page-container">
            <div className="page-container1 wrapper">
                <div className="page-container2">
                <div className="page-container3">
                    <img
                    alt="logo"
                    src="logo_horizontal.png"
                    className="page-image"
                    />
                </div>
                <div className="page-container4">
                    <Button 
                        style={SideBar_Styles.PageBut}
                        text="Data Visualize"
                        onClick = {() => this.getTablesDetails()}
                 
                    />
                    <Button 
                        style={SideBar_Styles.PageBut}
                        text="Machine Profile"
                        onClick = {() => {}}
                
                    />

                    {/* <button type="button" className="page-button button">
                    Data Visualize
                    </button>
                    <button type="button" className="page-button1 button">
                    Machine Profile
                    </button> */}
                    <p className="page-text">
                    <span>----------------------</span>
                    <br></br>
                    </p>
                    <Button 
                        style={SideBar_Styles.PageBut_b}
                        text="Logout"
                        onClick = {() => this.props.Logout_Callback('false')}
                        
                    />
                </div>
                </div>
                <div className="page-container5 ">
                <div className="page-container6">
                    <header data-thq="thq-navbar" className="page-navbar-interactive">
                    <div
                        data-thq="thq-navbar-nav"
                        className="page-desktop-menu"
                    ></div>
                    <div data-thq="thq-burger-menu" className="page-burger-menu">
                        <svg viewBox="0 0 1024 1024" className="page-icon">
                        <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                        </svg>
                    </div>
                    <div data-thq="thq-mobile-menu" className="page-mobile-menu">
                        <div className="page-nav">
                        <div className="page-top">
                            <span className="page-text06">
                            Factgie Agriculture Technology
                            </span>
                            <div data-thq="thq-close-menu" className="page-close-menu">
                            <svg viewBox="0 0 1024 1024" className="page-icon2">
                                <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                            </svg>
                            </div>
                        </div>
                        <nav className="page-links">
                            <span className="page-text07">Data Visualize</span>
                            <span className="page-text08">Machine Profile</span>
                        </nav>
                        <div className="page-buttons">
                            <button className="page-login button">Login</button>
                        </div>
                        </div>
                    </div>
                    </header>
                    {this.state.MainWindow?(<h1 className="page-text09" >{this.state.MainWindow}</h1>):(undefined)}
                    {/* <h1 className="page-text09">Data Visualize</h1> */}
                </div>

                <div className="page-container7 ">
                    <div className="page-container8">
                    {this.state.n_tn>0?(<p><Select onChange={(e)=>this.getTablesDetails(e)} options={tablelist}/></p>):(undefined)} 
                    {this.state.n_did>0?(<p><Select onChange={(e)=>this.GetYear(e)} options={deviceID}/></p>):(undefined)} 
                    {this.state.n_year>0?(<p><Select onChange={(e)=>this.GetMonth(e)} options={datayear}/></p>):(undefined)} 
                    {this.state.n_month>0?(<Select onChange={(e)=>this.GetDay(e)} options={datamonth}/>):(undefined)}
                    {this.state.datapointlimit>0?(<Select onChange={(e)=>this.setState({chartinfo_day:e})} options={dataday}/>):(undefined)}
                    </div>
                    {this.state.n_tn>0?(<Button 
                        text="Confirm"
                        onClick = {() => this.getChartData()}
                    />):(undefined)} 

                    {this.state.display==="true"?(<Line style={PageStyles.Chart} options={options} data={CHARTDATA}/>):(undefined)}
                </div>


                
                </div>
            </div>
        </div>
            
        


        /*     <div style={PageStyles.MainPage}>
                <div style={SideBar_Styles.MainContainer}>

                    <Pressable onPress={() => this.props.Navigator(-1)}>
                        <img style={SideBar_Styles.Icon} alt="Icon" src="logo_horizontal.png"/>
                    </Pressable>
                    <Button 
                        id = "btnData"
                        icon="chart" 
                        style={SideBar_Styles.SubmitButton}
                        text="Data Visualize"
                        onClick = {() => this.getTablesDetails()}
                        onMouseEnter = {() => this.Btn_OverIn('btnData')}
                        onMouseLeave = {() => this.Btn_OverOut('btnData')}
                    />
                    <Button 
                        id = "btnProfile"
                        icon="settings" 
                        style={SideBar_Styles.SubmitButton}
                        text="Machine Profile"
                        onClick = {() => {}}
                        onMouseEnter = {() => this.Btn_OverIn('btnProfile')}
                        onMouseLeave = {() => this.Btn_OverOut('btnProfile')}
                    />

                    <hr style={SideBar_Styles.Line}/>
                    <Button 
                        id = "btnLogout"
                        icon="log-out" 
                        style={SideBar_Styles.SubmitButton}
                        text="Logout"
                        onClick = {() => this.props.Logout_Callback('false')}
                        onMouseEnter = {() => this.Btn_OverIn('btnLogout')}
                        onMouseLeave = {() => this.Btn_OverOut('btnLogout')}
                    />
            </div>







            <div id="mainwindow" style={PageStyles.ContentDisplay}>
            
                {this.state.MainWindow?(<h1>{this.state.MainWindow}</h1>):(undefined)}
               
                {this.state.n_tn>0?(<p><Select onChange={(e)=>this.getTablesDetails(e)} options={tablelist}/></p>):(undefined)} 
                {this.state.n_did>0?(<p><Select onChange={(e)=>this.GetYear(e)} options={deviceID}/></p>):(undefined)} 
                {this.state.n_year>0?(<p><Select onChange={(e)=>this.GetMonth(e)} options={datayear}/></p>):(undefined)} 
                {this.state.n_month>0?(<Select onChange={(e)=>this.GetDay(e)} options={datamonth}/>):(undefined)}
                {this.state.datapointlimit>0?(<Select onChange={(e)=>this.setState({chartinfo_day:e})} options={dataday}/>):(undefined)}  
                {this.state.n_tn>0?(<Button 
                    style={SideBar_Styles.SubmitButton}
                    text="Confirm"
                    onClick = {() => this.getChartData()}
                    onMouseEnter = {() => this.Btn_OverIn('btnLogout')}
                    onMouseLeave = {() => this.Btn_OverOut('btnLogout')}
                />):(undefined)} 
               
                {this.state.display==="true"?(<Line style={PageStyles.Chart}options={options} data={CHARTDATA}/>):(undefined)}
            </div>
        </div> */
    )}
}
